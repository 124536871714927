import "./Marketing.css";
import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

function Marketing() {
  const [text1, setText1] = useState(
    "At MNS Constructions, our journey reflects our passion for craftsmanship and innovation."
  );
  const [text2, setText2] = useState(
    "From modest beginnings to becoming a trusted name in interior design and construction, our story is rooted in creativity, dedication, and a steadfast commitment to quality. We turn visions into reality, creating spaces that inspire and endure."
  );
  const [text3, setText3] = useState(
    "Together, we challenge conventions, foster innovation, and deliver outstanding results for our clients."
  );
  const [storyColor, setStoryColor] = useState("white");
  const [processColor, setProcessColor] = useState("grey");
  const [teamColor, setTeamColor] = useState("grey");
  const [isUnderlined, setIsUnderlined] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  function setProcess() {
    setText1(
      "Our process is a harmonious blend of expertise, always leading to exceptional outcomes."
    );
    setText2(
      "At MNS Constructions, we merge technical precision with creative vision to design and execute spaces that captivate and inspire."
    );
    setText3(
      "Every phase, from planning to finishing, is meticulously executed to deliver outstanding results and surpass expectations."
    );
    setProcessColor("white");
    setStoryColor("grey");
    setTeamColor("grey");
  }

  function setStory() {
    setText1(
      "Meet the core of MNS Constructions—the team behind every successful project."
    );
    setText2(
      "A dedicated group of skilled professionals and creative thinkers, united by a passion for transforming spaces, brings diverse expertise and innovative ideas to every project."
    );
    setStoryColor("white");
    setProcessColor("grey");
    setTeamColor("grey");
  }

  function setTeam() {
    setText1(
      "At MNS Constructions, our journey is one of passion and innovation. From modest beginnings to becoming a leading force in interior design and construction, our story is built on creativity, resilience, and excellence."
    );
    setText2(
      "A dedicated group of skilled professionals and creative thinkers, united by a passion for transforming spaces, brings diverse expertise and innovative ideas to every project."
    );
    setText3(
      "Together, we push boundaries, drive innovation, and deliver success for our clients."
    );
    setTeamColor("white");
    setStoryColor("grey");
    setProcessColor("grey");
  }

  const buttonStyle = (color) => ({
    color: color,
    border: "none",
    borderBottom: `2px solid ${color === "grey" ? "grey" : "white"}`,
    padding: "10px 20px",
    cursor: "pointer",
    textDecoration: isUnderlined ? "underline" : "none",
  });

  function openWhatsapp() {
    window.open(
      "https://wa.me/7982390147?text=Hello%20MNS,%20I%20am%20interested%20in%20your%20services",
      "_blank"
    );
  }

  function openInsta() {
    window.open(
      "https://www.instagram.com/auburnstudio__?igsh=dTE0aGF2YmFnN2Y0"
    );
  }

  return (
    <>
      <nav className="navbar">
        <Link to="/">
          <img src="logo2.png" alt="Company Logo" className="logo" />
        </Link>
        <div className={`nav-links ${isMenuOpen ? "mobile" : ""}`}>
        <Link to="/">
            Home <span className="nav-co">04</span>
          </Link>
          <Link to="/production">
            About <span className="nav-co">04</span>
          </Link>
          <a href="#service">
            Service <span className="nav-co">06</span>
          </a>
          <a href="#contact">
            Contact <span className="nav-co">us</span>
          </a>
        </div>
        <div className="hamburger" onClick={handleToggle}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </nav>

      <div className="home-pg">
        <div className="home-pg1">
          100+
          <span className="nav-co">PROJECTS</span>
        </div>
        <div className="home-pg2">
          WE BUILD <br /> EXPERIENCES <br /> FOR SPACES.
        </div>
      </div>
      <div className="talk">
        <div className="talk1">
          <button onClick={openWhatsapp}>CONNECT ↗</button>
        </div>
        <div className="talk2">
          <p>
            MNS Constructions is a leading interior contracting firm,
            specializing in comprehensive design, construction, and finishing
            solutions. With expertise in creating residential and commercial
            spaces, we bring your vision to life through meticulous planning,
            innovative designs, and flawless execution, ensuring each project
            exceeds expectations.
          </p>
        </div>
      </div>
      <div className="home-pg" id="about">
        <div className="home-pg1">
          <span className="nav-co">COMPANY</span>
        </div>
        <div className="home-pg3">
         We are a creative firm dedicated to providing
          unique and adaptable interior solutions for every type of client.
        </div>
      </div>
      <div className="story">
        <div className="story1">
          <img
            src="1.21.jpg"
            className="responsive-image"
            alt="Marketing Image"
          ></img>
        </div>
        <div className="story2">
          <button style={buttonStyle(storyColor)} onClick={setStory}>
            STORY
          </button>
          <button style={buttonStyle(processColor)} onClick={setProcess}>
            PROCESS
          </button>
          <button style={buttonStyle(teamColor)} onClick={setTeam}>
            OUR TEAM
          </button>
        </div>
        <div className="story3">
          <p>{text1}</p>
          <p>{text2}</p>
          <p>{text3}</p>
        </div>
      </div>
      <div
        className="home-pg"
        style={{ backgroundColor: "#EFEFEF", width: "100%", marginTop: "5rem" }}
        id="service"
      >
        <div className="home-pg1">
          <span className="nav-co" style={{ color: "black" }}>
            SERVICES
          </span>
        </div>
        <div
          className="home-pg3"
          style={{ color: "black", fontWeight: "bolder", paddingRight: "3rem" }}
        >
          We create experiences and turn ideas into reality
          <div
            style={{
              fontWeight: "lighter",
              fontSize: "1rem",
              paddingTop: "3rem",
            }}
          >
            <p>
              We bring visions to life and turn concepts into reality.
              <br /> At MNS Constructions, our journey is one of passion and
              innovation. <br /> From modest beginnings to becoming a leading
              force in interior design and
              <br />
              construction, our story is built on creativity, resilience, <br />{" "}
              and excellence.
            </p>
          </div>
          <div style={{ fontWeight: "lighter", fontSize: "1rem" }}>
            <p>
              We transform spaces with meticulous planning, expert
              craftsmanship, 
              <br />and attention to detail. Our innovative designs and high-quality finishes ensure
              that <br /> every project not only meets but exceeds expectations{" "}
              <br />leaving a lasting impact.
            </p>
          </div>
          <div style={{ fontWeight: "lighter", fontSize: "1rem" }}>
          </div>
        </div>
      </div>
      <div className="services" style={{ backgroundColor: "#EFEFEF" }}>
        <div className="services1">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>
            INTERIOR DESIGN AND PLANNING
          </h3>
        </div>
        <div className="services2">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>RENOVATION AND REMODELLING</h3>
        </div>
        <div className="services3">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>
            FLOORING AND TILING SOLUTIONS{" "}
          </h3>
        </div>
        <div className="services4">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>LIGHTING DESIGN AND INSTALLATION</h3>
        </div>
      </div>
      <div className="services" style={{ backgroundColor: "#EFEFEF" }}>
        <div className="services5">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>
            PROJECT MANAGEMENT AND SUPERVISION
          </h3>
        </div>
        <div className="services6">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>LAYOUT CONSULTING</h3>
        </div>
        <div className="services7">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>SPACE OPTIMIZATION </h3>
        </div>
        <div className="services8">
          <img src="services-img.png" />
          <h3 style={{ fontWeight: "lighter", color: "black" }}>
            CUSTOM FURNITURE
          </h3>
        </div>
      </div>
      <div className="home-pg" id="case-studies">
        <div className="home-pg1">
          <span className="nav-co">
            Everything begins with <br /> to emotions
          </span>
        </div>
        <div className="home-pg3">
          Highlighting Our Most Impactful Projects, See the Success Stories
          We’ve Created
        </div>
      </div>
      <div className="videos">
        <div className="videos1">
          <div className="video-container">
            <div>
              <video
                className="small-video"
                src="service5.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
            </div>
            <div className="greys">
              <p className="grey">IPMI</p>
              <p className="grey">ⓒ 2024</p>
            </div>
          </div>
          <div className="video-container">
            <video
              className="small-video"
              src="service6.mp4"
              autoPlay
              muted
              loop
              playsInline
            ></video>
            <div className="greys">
              <p className="grey">GAUR CITY</p>
              <p className="grey">ⓒ 2024</p>
            </div>
          </div>
        </div>
        <div className="videos2">
          <div className="video-container">
            <video
              className="small-video"
              src="service7.mp4"
              autoPlay
              muted
              loop
              playsInline
            ></video>
            <div className="greys">
              <p className="grey">PARAMOUNT INDUSTRIES</p>
              <p className="grey">ⓒ 2024</p>
            </div>
          </div>
          <div className="video-container">
            <video
              className="small-video"
              src="service8.mp4"
              autoPlay
              muted
              loop
              playsInline
            ></video>
            <div className="greys">
              <p className="grey">AHSAN HOSPITAL</p>
              <p className="grey">ⓒ 2024</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="case">
        <button onClick={openInsta}>ALL CASE STUDIES</button>
      </div> */}

      <div className="contact" id="contact">
        <div className="contact1">
          <p>Let’s design and create your perfect space together.</p>
        </div>
        <div className="contact2">
          <button onClick={openWhatsapp}>Let's Talk</button>
        </div>
      </div>
      <div className="footer">
      <div>
            <img src='logo2.png' className="footer-logo"></img>
          </div>
        <div>
          <div>
            <h1>MNS Constructions</h1>
          </div>
          <div>
            <p className="grey">ⓒ Copyright 2024. All rights reserved</p>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}

export default Marketing;
