import "./Home.css";
import RotatingCircle from "./RotatingCircle";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";
import "./Marketing.css";
function Home() {
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };
  function openWhatsapp() {
    window.open(
      "https://wa.me/7982390147?text=Hello%20MNS,%20I%20am%20interested%20in%20your%20services",
      "_blank"
    );
  }
  const [formData, setFormData] = useState({
    name: { value: "", error: "" },
    email: { value: "", error: "" },
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = "";

    // Validation for name
    if (name === "name") {
      error = /^[a-zA-Z\s]+$/.test(value) ? "" : "Enter a valid name";
    }

    // Validation for email
    if (name === "email") {
      error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ? ""
        : "Enter a valid email";
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: { ...prevData[name], value, error },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Check if there are any validation errors
    if (formData.name.error || formData.email.error) {
      console.error("Form has validation errors");
      return; // Exit function if there are validation errors
    }

    // If validation passes, construct the URL with form data
    const url = `https://script.google.com/macros/s/AKfycbyub8q3BgVA1zDERUUnujEucFUbSI6bGHyyEZsD20lX5oDNF5mvNVPQKYPQm9qLTaZD6g/exec?name=${encodeURIComponent(
      formData.name.value
    )}&email=${encodeURIComponent(
      formData.email.value
    )}&message=${encodeURIComponent(formData.message)}`;

    // Execute the constructed URL
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("Google Sheet submission successful:", data);
        // Additional logic after successful Google Sheet submission
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error submitting to Google Sheet:", error);
        // Additional error handling logic
      });
  };
  return (
    <div className="Home">
      <nav className="navbar">
      <Link to="/">
          <img src="logo2.png" alt="Company Logo" className="logo" />
        </Link>
        <div className={`nav-links ${isMobile ? "mobile" : ""}`}>
          <Link to="/">
            Home<span className="nav-co">04</span>
          </Link>
          <Link to="/marketing">
            Our Company <span className="nav-co">06</span>
          </Link>
          <a href="#about">
            About Us <span className="nav-co">us</span>
          </a>
          <a href="#contact">
            Contact <span className="nav-co">us</span>
          </a>
        </div>
        <div className="hamburger" onClick={handleToggle}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      <div className="top-div">
        <div className="headings">
          Modern Design Solutions <br /> For Every Space
          <br />
          <span className="heading-sub">
            <p>
              INTERIOR &nbsp;&nbsp;|&nbsp;&nbsp; RENOVATION &nbsp;&nbsp;
              |&nbsp;&nbsp; REMODELLING &nbsp;&nbsp;| &nbsp;&nbsp;CONSULTANCY
            </p>
          </span>
        </div>
        <div className="para">
          "With a deep commitment to quality, we source the finest materials
          from across India and meticulously craft interiors with unparalleled
          attention to detail
          <br />
          Whether you envision a luxurious living space or a standout corporate
          environment, we turn your vision into an extraordinary reality."
        </div>
      </div>
      <div className="circle">
        <RotatingCircle></RotatingCircle>
      </div>
      <div className="service">OUR SERVICES</div>
      <div className="cardsdiv">
        <div className="card1">
          <ul class="cards">
            <li>
              <Link to="/production" class="card">
                <img src="2.jpg" class="card__image" alt="" />
                <div class="card__overlay">
                  <div class="card__header">
                    <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img class="card__thumb" src="logo2.png" alt="" />
                    <div class="card__header-text">
                      <h3 class="card__title">DESIGN AND PLANNING</h3>
                      <span class="card__status"></span>
                    </div>
                  </div>
                  <p class="card__description">
                    We combine creativity and expertise to transform your space
                    into something uniquely functional and visually stunning.
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="card2">
          <ul class="cards">
            <li>
              <Link to="/marketing" class="card">
                <img src="1.jpg" class="card__image" alt="" />
                <div class="card__overlay">
                  <div class="card__header">
                    <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img class="card__thumb" src="logo2.png" alt="" />
                    <div class="card__header-text">
                      <h3 class="card__title">CONSTRUCTION AND RENOVATION</h3>
                      <span class="card__status"></span>
                    </div>
                  </div>
                  <p class="card__description">
                    Our expert team transforms your vision into a beautiful,
                    cohesive environment that perfectly suits your
                    lifestyle and needs.
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="card2">
          <ul class="cards">
            <li>
              <Link to="/marketing" class="card">
                <img src="12.jpg" class="card__image" alt="" />
                <div class="card__overlay">
                  <div class="card__header">
                    <svg class="card__arc" xmlns="http://www.w3.org/2000/svg">
                      <path />
                    </svg>
                    <img class="card__thumb" src="logo2.png" alt="" />
                    <div class="card__header-text">
                      <h3 class="card__title">FINISHING AND EXECUTION</h3>
                      <span class="card__status"></span>
                    </div>
                  </div>
                  <p class="card__description">
                    Our Finishing and Execution services ensure that every
                    detail of your project is flawlessly completed, with
                    precision and care.
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <h1 className="heading-about" id="about">
        About Us
      </h1>
      <div className="About">
        <div className="about-para">
          <p>
            At MNS Constructions, we pride ourselves on sourcing the finest
            materials from across India and applying meticulous attention to
            detail in every project. Our custom designs are crafted with care,
            resulting in beautiful and functional interiors that stand out
          </p>
        </div>
        <div className="about-stats">
          <div class="stat">
            <h2>25+</h2>
            <p>years of work</p>
          </div>
          <div class="stat">
            <h2>50+</h2>
            <p>professionals</p>
          </div>
          <div class="stat">
            <h2>100+</h2>
            <p>successful Projects</p>
          </div>
        </div>
      </div>
      <div class="scrolling-strip">
        <div class="scrolling-text">
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
          <span>• TRANSFORMING SPACES •</span>
        </div>
      </div>
      <div className="containers">
        <h1 id="contact" className="containers-heading">
          CONTACT WITH US
        </h1>
        {/* <p className="containers-para">
          Leave your details and we will be in touch today!
        </p> */}
        <div className="form-container">
          <div className="form1">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Enter your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your Name"
                value={formData.name.value}
                onChange={handleChange}
                style={{
                  borderBottom: `1px solid ${
                    formData.name.error ? "red" : "white"
                  }`,
                }}
                required
              />
              {formData.name.error && (
                <span style={{ color: "red", fontSize: "0.7rem" }}>
                  {formData.name.error}
                </span>
              )}

              <label htmlFor="email">Enter your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your Email"
                value={formData.email.value}
                onChange={handleChange}
                style={{
                  borderBottom: `1px solid ${
                    formData.email.error ? "red" : "white"
                  }`,
                }}
                required
              />
              {formData.email.error && (
                <span style={{ color: "red", fontSize: "0.7rem" }}>
                  {formData.email.error}
                </span>
              )}

              <label htmlFor="message">
                Describe your project and the questions that interest you:
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Text here..."
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              ></textarea>

              <button type="submit" className="submit-btn">
                Let's Talk
              </button>
            </form>
          </div>
          <div className="image1">
            <img
              src="mns-head.jpg"
              alt="Team Meeting"
              className="img-contact"
            />

          </div>
        </div>
      </div>
      <div className="img-company">
      <img src="1.20.jpg" className="img-company"/>
      </div>
      <div className="contact" id="contact">
        <div className="contact1">
          <p>Let’s design and create your perfect space together</p>
        </div>
        <div className="contact2">
          <button onClick={openWhatsapp}>Let's Talk</button>
        </div>
      </div>
      <div className="footer">
      <div>
            <img src='logo2.png' className="footer-logo"></img>
          </div>
        <div>
          <div>
            <h1>MNS Constructions</h1>
          </div>
          <div>
            <p className="grey">ⓒ Copyright 2024. All rights reserved</p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
export default Home;
