import "./Production.css";
import React, { useEffect, useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

function Production() {
  const containerRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const isInViewport = rect.top >= 0 && rect.bottom <= window.innerHeight;
        const isScrolledPast = rect.bottom < 0 || rect.top > window.innerHeight;

        setIsScrolled(isInViewport && !isScrolledPast);
      }
    };

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);
    // Initial check
    handleScroll();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };
  function openWhatsapp() {
    window.open(
      "https://wa.me/7982390147?text=Hello%20MNS,%20I%20am%20interested%20in%20your%20services",
      "_blank"
    );
  }
  return (
    <div>
      <nav className="navbar">
        <Link to="/">
          <img src="logo2.png" alt="Company Logo" className="logo" />
        </Link>
        <div className={`nav-links ${isMobile ? "mobile" : ""}`}>
        <Link to="/production">
            About <span className="nav-co">04</span>
          </Link>
          <a href="#service">
            Services <span className="nav-co">06</span>
          </a>
          {/* <a href="#case-studies">
            Case Studies <span className="nav-co">04</span>
          </a> */}
          <Link to="/marketing">
            Our Company <span className="nav-co">06</span>
          </Link>
          <a href="#contact">
            Contact <span className="nav-co">us</span>
          </a>
        </div>
        <div className="hamburger" onClick={handleToggle}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>
      </nav>
      <div className="main">
        <div className="heading">
          <div className="main-heading">
            Elevate your Spaces
            <br /> with our Experts
          </div>
          <div className="sub-heading">
            Transforming spaces into experiences
          </div>
          <div className="but-head">
            <a href="#talk" className="but-heading">
              Get Started
            </a>
          </div>
        </div>
        <div className="main-img">
          <div className="mi-flex1" id="col-1">
            <img src="1.1.jpg" id="img-1" alt="img1" />
            <img src="1.2.jpg" alt="img2" />
            <img src="1.3.jpg" id="img-2" alt="img3" />
          </div>
          <div className="mi-flex1">
            <img src="1.4.jpg" id="img-1" alt="img4" />
            <img src="1.5.jpg" alt="img5" />
            <img src="1.6.jpg" id="img-2" alt="img6" />
          </div>
          <div className="mi-flex1" id="col-1">
            <img src="1.7.jpg" id="img-1" alt="img7" />
            <img src="1.8.jpg" alt="img8" />
            <img src="1.9.jpg" id="img-2" alt="img9" />
          </div>
        </div>
      </div>
      <div className="intro">
        <div className="intro-img" id="desktop-only">
          <img src="1.10.jpg" className="intro-image" alt="intro" />
        </div>

        <div className="intro-text" id="about">
          <p className="intro-text-heading">
            Construction Doesn't Have to Be Expensive or Complicated!
            <br /> It Should Be Smart & User Friendly
          </p>
          <div className="intro-img" id="mobile-only">
            <img src="1.10.jpg" className="intro-image" alt="intro" />
          </div>
          <p className="intro-text-desc">
            Our approach ensures that each project is smartly executed,
            prioritizing both efficiency and user-friendliness. We believe in
            delivering quality results that are both accessible and aligned
            with your needs.
          </p>
        </div>
      </div>
      <div className="services" id="service">
        <div
          ref={containerRef}
          className={`container ${isScrolled ? "scrolled" : ""}`}
        >
          <div data-text="Design" style={{ "--r": "-15" }} className="glass">
            <a href="#created-direction">
              <img src="1.11.jpg" alt="service1" />
            </a>
          </div>
          <div
            data-text="Construction"
            style={{ "--r": "5" }}
            className="glass"
          >
            <a href="#hair-makeup">
              <img src="1.12.jpg" alt="service2" />
            </a>
          </div>
          <div data-text="Finishing" style={{ "--r": "25" }} className="glass">
            <a href="#shoots">
              <img src="1.14.jpg" alt="service3" />
            </a>
          </div>
        </div>
      </div>
      <div className="service-main">
        <div className="service-heading">
          Transforming Spaces with Precision & Passion
        </div>
        <div className="services-desc">
          <div className="service-exp" id="created-direction">
            <div className="service-img">
              <img src="1.15.jpg"></img>
            </div>
            <div className="service-text">
              <div className="service-text-heading">Design</div>
              <p className="service-text-desc">
                We begin by transforming your ideas into a thoughtful,
                functional, and visually stunning plan. Our design process
                ensures that every detail reflects your vision and style,
                creating spaces that are both innovative and practical.
              </p>
            </div>
          </div>
          <div className="service-exp" id="hair-makeup">
            <div className="service-img" id="mobile-only">
              <img src="1.16.jpg"></img>
            </div>
            <div className="service-text">
              <div className="service-text-heading">Construction</div>
              <p className="service-text-desc">
                Our construction team brings your design to life with precision
                and expertise. From structural work to intricate detailing, we
                handle every aspect of the build, ensuring that your project is
                executed to the highest standards of quality and safety.
              </p>
            </div>
            <div className="service-img" id="desktop-only">
              <img src="1.16.jpg"></img>
            </div>
          </div>
          <div className="service-exp" id="shoots">
            <div className="service-img">
              <img src="1.17.jpg"></img>
            </div>
            <div className="service-text">
              <div className="service-text-heading">Finishing</div>
              <p className="service-text-desc">
                In the finishing phase, we focus on the fine details that make
                your space truly special. Our commitment to excellence ensures a
                polished, high-quality result, where every element is perfectly
                aligned to your design vision.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="contact" id="contact">
        <div className="contact1">
          <p>Let’s design and create your perfect space together.</p>
        </div>
        <div className="contact2" id="talk">
          <button onClick={openWhatsapp}>Let's Talk</button>
        </div>
      </div>
        <div className="footer">
          <div>
            <img src='logo2.png' className="footer-logo"></img>
          </div>
          <div>
            <div>
              <h1>MNS Construction</h1>
            </div>
            <div>
              <p className="grey">ⓒ Copyright 2024. All rights reserved</p>
            </div>
          </div>
          <div></div>
        </div>
    </div>
  );
}

export default Production;
