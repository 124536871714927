import React from 'react';
import './RotatingCircle.css';

const RotatingCircle = () => {
  return (
    <div className="rotating-circle-container">
      <svg className="rotating-circle" width="150" height="150" viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path id="circlePath" d="M 75,75 m -70,0 a 70,70 0 1,0 140,0 a 70,70 0 1,0 -140,0" />
        </defs>
        <circle cx="75" cy="75" r="70" stroke="white" strokeWidth="4" fill="white" />
        <text fill="black" fontSize="12" fontFamily="Arial">
          <textPath href="#circlePath" startOffset="20%">
          WATCH MORE WATCH MORE WATCH MORE WATCH MORE
          </textPath>
        </text>
        <g className="arrow">
          <line x1="75" y1="55" x2="75" y2="95" stroke="black" strokeWidth="4" />
          <line x1="75" y1="55" x2="85" y2="65" stroke="black" strokeWidth="4" />
          <line x1="75" y1="55" x2="65" y2="65" stroke="black" strokeWidth="4" />
        </g>
      </svg>
    </div>
  );
}

export default RotatingCircle;